<template>
    <auth-wrap class="login">
      <div class="login--content">
        <h2 class="login--title-one">Bienvenue sur OBY</h2>
        <h3 class="login--title-two">Votre outil d'évaluation</h3>
        <div class="login__information">
            <img width="24" class="login__img" src="@/assets/info.svg"/>
            <p>
                Ceci est votre première connexion à OBY avec PSC (PRO SANTE CONNECT).
                Veuillez vous connecter avec vos identifiants OBY pour synchroniser 
                vos comptes OBY et PSC.
            </p>
        </div>
        <form class="login--form">
          <div class="mt-auto">
            <div class="p-2 form-group custom-form custom-form--input input--login" v-if="loginError">
              <slot name="error">
                <small class="login--error">{{ loginError }}</small>
              </slot>
            </div>
            <div class="p-2 custom-form--input active-value-label input--login">
              <form-input
                :error="errors.email"
                label="Login"
                :login-input="false"
                v-model="email"
                type="email"
                :required="true"
                @input="inputEmail"
              />
            </div>
            <div class="p-2 custom-form--input mt-1 active-value-label input--login">
              <form-input
                :error="errors.password"
                label="Mot de passe"
                :login-input="false"
                v-model="password"
                type="password"
                :required="true"
                @input="inputPassword"
              />
            </div>
          </div>
          <div class="mt-auto d-flex justify-content-center">
            <button class="login__btn"
                    style="min-width: 187px;"
                    :disabled="disabled"
                    @click.prevent="handlerLogin">
              <font-awesome-icon v-show="loading" :icon="['fas', 'spinner']" spin/> Connexion</button>
          </div>
          <div class="d-flex justify-content-center login__deconexion">
            <a href="#" @click.prevent="$router.push({name: 'forgotPassword'})">Mot de passe oublié</a>
          </div>
        </form>
      </div>
    </auth-wrap>
  </template>
  <script>
  import {mapActions, mapState} from "vuex";
    export default {
      name: "LoginPSC",
      data() {
        return {
          email: null,
          password: null,
          errors: {
            email: "",
            password: "",
          },
          loginError: null,
          loading: false
        };
      },
      computed: {
        ...mapState('User', ['connectedUser']),
        userNotEmpty() {
          return this.email !== null && this.email !== "" && this.password !== null && this.password !== "";
        },
        disabled() {
          return !this.userNotEmpty || this.loading;
        }
      },
      methods: {
        ...mapActions('User', ["loginUser", 'getUserConnected']),
        handlerLogin() {
          this.loading = true;
          this.loginUser({
            email: this.email,
            password: this.password
          }).then((res) => {
            if (this.$route.path.includes('evaluation')) {
              this.$router.push({name: 'accueil'});
            } else {
              this.$router.push({name: 'client-list'});
            }
            this.loading = false;
            this.errors = {};
            this.loginError = null;
            return res;
          }).catch((err) => {
            this.loading = false;
            this.errors.email = ' ';
            this.errors.password = ' ';
            this.loginError = '* Login et mot de passe incorrects';
            throw err;
          });
        },
        inputEmail() {
          this.errors.email = '';
          this.loginError = null;
        },
        inputPassword() {
          this.errors.password = '';
          this.loginError = null;
        },
      }
    };
  </script>