<template>
    <eval-wrap v-if="this.$route.path.includes('evaluation')">
        <Cgu/>
    </eval-wrap>
    <cgu-wrap  v-else-if="this.$route.path === '/cgu'">
        <Cgu/>
    </cgu-wrap>
    <Cgu v-else />
</template>
<script>
import Cgu from '@/components/CGU/Cgu';
export default {
    name: "CgdView",
    components: {
        Cgu
    }
}
</script>