<template>
    <div class="wrapper wrapper--bo">
        <header class="authWrap__header">
            <div class="container-fluid">
                <NavBar/>
            </div>
        </header>
        <main class="cgu--main">
            <slot />
        </main>
        <Footer></Footer>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import NavBar from '@/layouts/NavBar';
import Footer from '@/components/Footer';
export default {
    name: "CguWrap",
    components: {
        NavBar,
        Footer
    },
    computed: {
        ...mapState("User", ["connectedUser"]),
    }
};
</script>
<style scoped>
.wrapper  {
    background-color: #F5F5F5;
    height: initial !important;
    text-align: justify;
}
</style>