export default {
    setClientHistory(state, data) {
        if (data.length < 10) {
            state.reloadClientHistory = false;
        }
        state.clientHistory = [state.clientHistory.shift(), ...data]
    },
    setClientStatusHistory(state, data) {
        if (data.length < 10) {
            state.reloadClientStatusHistory = false;
        }
        state.clientStatusHistory = [state.clientStatusHistory.shift(), ...data];
    },
    setInsiHistory(state, data) {
        if (data.length < 10) {
            state.reloadInsiHistory = false;
        }
        state.insiHistory = [state.insiHistory.shift(), ...data];
    },
}