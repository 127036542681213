<template>
  <div class="navbar">
    <div class="nav__content">
      <img class="avatar" src="@/assets/avatar.png" width="52" />
      <div class="user-info">
        <span>{{ getFirstname }} {{ getLastname }}</span>
      </div>
      <div v-if="accessProSanteConnect && (!isloggedPSC || autoLogout)" class="wrapper">
        <div class="icon infobulle">
          <span class="tooltip position-pointer">Vous êtes déconnectés de PRO SANTE CONNECT, veuillez vous reconnecter en cliquant sur PRO SANTE CONNECT</span>
          <span><img src="@/assets/warning_pro_sante_connect.svg" /></span>
        </div>
      </div>
      <img class="cursor-pointer position-pointer" style="margin-left: 20px;" v-if="accessProSanteConnect" @click.prevent="openWindowPSC" :src="(isloggedPSC && !autoLogout)
        ? require('@/assets/pro_sante_connect.svg')
        : require('@/assets/not_connected_pro_sante_connect.svg')"
      />
    </div>
    <img class="logo cursor-pointer" src="@/assets/logo.png" @click.prevent="gotToHome"/>
    <div class="eval--header-btn-wrap">
      <a class="btn" v-if="accessRollback" @click.prevent="gotToHome">
        <img src="@/assets/bouton-primaire-home.svg" />
      </a>
      <router-link 
        v-if="
          $route.name !== 'cr'
          && $route.name !== 'devis'
          && $route.name !== 'login'
          && $route.name !== 'loginPsc'
          && connectedUser
          && accessCr
          && (this.$route.path !== '/evaluation-accueil' && this.$route.path !== '/login' && this.$route.path !== '/evaluation-login')
        "
        :to="{name:'cr'}" 
        class="btn" 
        data-prefix="2"
      >
        <img src="@/assets/bouton-primaire-cr.svg" />
      </router-link>
      <router-link v-else :to="{name:'project-life'}" class="btn" data-prefix="2">
        <img src="@/assets/bouton-primaire-q.svg" />
      </router-link>
      <router-link
        v-if="accessBackoffice && (this.$route.path !== '/evaluation-accueil' && this.$route.path !== '/login' && this.$route.path !== '/evaluation-login')"
        :to="{name:'client-list'}" 
        class="btn" 
        data-prefix="2"
      >
        <img src="@/assets/bouton-primaire-switch.svg" />
      </router-link>
      <a v-if="this.$route.path !== '/evaluation-accueil' && this.$route.path !== '/login' && this.$route.path !== '/evaluation-login'" class="btn" @click.prevent="$bvModal.show('quit-evaluation')">
        <img src="@/assets/bouton-primaire-power-off.svg" width="32" />
      </a>
    </div>
  </div>
</template>
<script>
  import {LOCAL_STORAGE_EVALUATION_ID} from "@/components/Util/EnvVariable";
  import {mapActions, mapGetters, mapState} from "vuex";

  export default {
    computed: {
      ...mapGetters('Evaluation', ['calculateTime', 'getAggirPercentage']),
      ...mapGetters('User', ['isUserRoleIntervenant', 'isUserRoleOperationManagerOrAreaManager']),
      ...mapState("User", ['connectedUser']),
      hasLoader(){
        return this.$store.state.User.loading
            || this.$store.state.Prestation.loading
            || this.$store.state.Agency.loading
            || this.$store.state.Structure.loading
            || this.$store.state.Notification.loading
            || this.$store.state.Tarification.loading
            || this.$store.state.Contract.loading
            || this.$store.state.Filter.loading
            || this.$store.state.Client.loading
            || this.$store.state.Evaluation.loading
            || this.$store.state.Act.loading
            || this.$store.state.AggirQuestion.loading
            || this.$store.state.Material.loading
            || this.$store.state.Contact.loading;
      },
      accessBackoffice: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_BACK_OFFICE');
        }
      },
      accessProSanteConnect: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_AUTH_PSC');
        }
      },
      isloggedPSC: {
        get() {
          return (sessionStorage.getItem('isLoggedWithPSC') === 'true');
        }
      },
      accessCr: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_VIEW_CR');
        }
      },
      accessRollback: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_ROLLBACK_LIST_CLIENT');
        }
      },
      getFirstname: {
        get() {
          return this.connectedUser?.firstName ?? '';
        }
      },
      getLastname: {
        get() {
          return this.connectedUser?.lastName ?? '';
        }
      }
    },
    data() {
      return {
        autoLogout: false,
      };
    },
    methods: {
      ...mapActions('User', ["loginUserPSC", "loginUser", "logoutPSC", "redirectPSC"]),
      gotToHome() {
        window.sessionStorage.removeItem(LOCAL_STORAGE_EVALUATION_ID);
        this.$router.push({name: "accueil"});
      },
      openWindowPSC() {
        this.redirectPSC({}).then((res) => {
          const url = res.redirectUrl;
          const width = 800;
          const height = 600;
          const left = (window.screen.width / 2) - (width / 2);
          const top = (window.screen.height / 2) - (height / 2);
          const popupFeatures = `width=${width},height=${height},scrollbars=yes,resizable=yes,left=${left},top=${top}`;
          const popup = window.open(url, 'popupWindow', popupFeatures);

          const checkPopupClosed = setInterval(() => {
          if (popup.closed) {
            clearInterval(checkPopupClosed);
            this.onPopupClosed();
          }
        }, 500);
        });
      },
      onPopupClosed() {
        sessionStorage.setItem('PSC_AUTO_LOGOUT', Date.now().toString());

        this.loginUserPSC().then((res) => {
          if (res == "NO MATCH") {
            this.$router.push({name: 'loginPsc'})
            return;
          }

          this.loginUser({email: res, password: 'connectPSC'}).then((res) => {
          if (this.$route.path.includes('evaluation')) {
            if (this.$route.path.includes('evaluation-accueil')) {
              window.location.reload();
            }
            this.$router.push({name: 'accueil'});
          } else {
            this.$router.push({name: 'client-list'});
          }

          this.loading = false;
          this.errors = {};
          this.loginError = null;

          return res;
        }).catch((err) => {
          this.loading = false;
          this.errors.email = ' ';
          this.errors.password = ' ';
          this.loginError = '* Login et mot de passe incorrects';

          throw err;
        })})
      },
      checkAutoLogout() {
        const storedTimestamp = sessionStorage.getItem('PSC_AUTO_LOGOUT');

        if (storedTimestamp) {
          const currentTime = Date.now();
          const elapsedTime = currentTime - parseInt(storedTimestamp, 10);
          const fourHours = 14400000;

          if (elapsedTime > fourHours) {
            sessionStorage.removeItem('PSC_AUTO_LOGOUT');
            sessionStorage.setItem('isLoggedWithPSC', 'false');
            this.logoutPSC();
            this.autoLogout = true;
          }
        }
      },
    },
    mounted() {
      this.autoLogoutInterval = setInterval(this.checkAutoLogout, 60000);
    },
    beforeDestroy() {
      clearInterval(this.autoLogoutInterval);
    }
  }
</script>
<style scoped>
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 5px;
  color: black;
  font-weight: bold;
  font-size: 15px;
}

.wrapper {
  display: inline-flex;
  list-style: none;
}

.position-pointer {
  margin-left: 10px !important;
}

.wrapper .icon {
  margin-left: 15px;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  top: 5px;
}

.wrapper .tooltip {
  position: absolute;
  top: 60px !important;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 15px 18px;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  width: 250px;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  background: #ffffff;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: 100px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .infobulle:hover .tooltip,
.wrapper .infobulle:hover .tooltip::before {
  background: #100249;
  color: #ffffff;
}

</style>