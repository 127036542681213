<template>
  <div class="wrapper wrapper--eval">
    <header class="eval--header" ref="topNav">
      <div class="container-fluid">
        <nav-bar/>
      </div>
      <div class="container-fluid eval--menu-wrap">
        <nav class="nav eval--nav row" ref="pdv--nav">
          <span class="nav-select" :style="style"></span>
          <a
              v-if="accessIdentity"
              class="nav-link"
              :class="{'active': routeName === 'identity' }"
              :href="`${routeNameReact}/new/evaluation/${this.evaluation.id}/identite/?idClient=${this.client.id}`">Identité</a>
          <router-link
              v-if="accessAutonomy"
              class="nav-link"
              :class="{'active': routeName === 'autonomy' }"
              :to="{name:'autonomy'}">Autonomie</router-link>
          <router-link
              v-if="accessHealth"
              class="nav-link"
              :class="{'active': routeName === 'soin' }"
              :to="{name:'soin'}">Santé</router-link>
          <router-link
              v-if="accessProjetIntervention"
              class="nav-link"
              :class="{'active': routeName === 'project-life' }"
              :to="{name:'project-life'}">Projet d'intervention</router-link>
        </nav>
      </div>
      <div class="container-fluid eval--submenu-wrap" id="title-wrap">
        <slot name="title">
        </slot>
      </div>
        <template>
          <InfoItem
            :profilPicture="profilPicture"
            :getBirthname="getBirthname"
            :getBirthFirstname="getBirthFirstname"
            :getBirthDate="getBirthDate"
            :getSex="getSex"
            :getCodeInsee="getCodeInsee"
            :getIdentificationStatus="getIdentificationStatus"
            :openModal="showHistoryModal"
          />
        </template>
    </header>
    <main class="eval--main" :style="mainStyle">
      <div class="eval--content">
        <div class="eval--container">
          <div class="eval--nav" id="eval-navbar">
          </div>
          <div class="eval--slot">
            <slot />
          </div>
        </div>
      </div>
      <div class="eval--right-bar">
        <nav class="nav nav--right-bar">
          <a v-if="accessInfos" href="#" class="nav-link" @click.prevent="modalInfos = true">
            <img src="@/assets/icon-plein-info.svg" alt />
          </a>
          <a v-if="accessRisks" href="#" class="nav-link" @click.prevent="showRisksModal">
            <img src="@/assets/icon-plein-risks.svg" alt />
          </a>
          <a v-if="accessAutonomie" href="#" class="nav-link" @click.prevent="openModalAutonomie">
            <img src="@/assets/warning.png" alt class="notifWarning" v-if="displayNotification" />
            <img src="@/assets/icon-plein-ajout.svg" alt />
          </a>
          <a v-if="accessContacts" href="#" class="nav-link" @click.prevent="modalContacts = true">
            <img src="@/assets/icon-plein-contacts.svg" alt />
          </a>

        </nav>
      </div>

      <infos-modal
          v-if="accessInfos"
          :active="modalInfos"
          :information="additionalInformation"
          @close="modalInfos=false"
          @validate="validateInfos" />

      <contacts-modal
          v-if="accessContacts"
          :active="modalContacts"
          @close="modalContacts=false"
          @remove="removeClientContact"
          @update="updateClientContact" />

      <risks-modal
          v-if="accessRisks"
          :active="modalRisks"
          :risks="evaluation.risks"
          @close="modalRisks=false"
          @add="assignRisk"
          @validate="validateRisks" />

      <autonomie-modal
          v-if="accessAutonomie"
          :active="modalAutonomie"
          @close="modalAutonomie=false" 
      />
      <history-modal
        :active="modalHistory"
        @close="showHistoryModal"
      />
    </main>
    <Footer></Footer>
  </div>
</template>
<script>

import ContactsModal from '@/components/Eval/ContactsModal';
import AutonomieModal from '@/components/Eval/AutonomieModal';
import RisksModal from '@/components/Eval/RisksModal';
import InfoItem from '@/components/Eval/InfoItem';
import InfosModal from '@/components/Eval/InfosModal';
import HistoryModal from '@/components/Eval/HistoryModal';
import Footer from '@/components/Footer';
import NavBar from '@/layouts/NavBar';

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "EvalWrap",
  components: {
    AutonomieModal,
    RisksModal,
    ContactsModal,
    InfosModal,
    HistoryModal,
    NavBar,
    InfoItem,
    Footer
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
  },
  data() {
    return {
      modalContacts: false,
      modalRisks: false,
      modalAutonomie: false,
      modalHistory: false,
      modalInfos: false,
      mainStyle: {}
    };
  },
  computed: {
    ...mapState("Client", ["client", "contact"]),
    ...mapState("Evaluation", ["evaluation"]),
    ...mapGetters('Evaluation', ['calculateTime']),
    routeName() {
      return this.$route.name;
    },
    routeNameReact() {
      const protocol = window.location.protocol;
      const domain = window.location.hostname;
      const port = window.location.port;
      const fullDomain = port ? `${protocol}//${domain}:3001` : `${protocol}//${domain}`;
      return fullDomain;
    },
    accessIdentity: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_ACCESS_IDENTITY');
        }
    },
    accessAutonomy: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_ACCESS_AUTONOMIE');
        }
    },
    accessInfos: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_COMPLETE_INFORMATION_KEEP');
        }
    },
    accessContacts: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_INFORM_MEDICAL');
        }
    },
    accessAutonomie: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_SELECT_MATERIALS');
        }
    },
    accessRisks: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_LIST_RISK');
        }
    },
    accessProjetIntervention: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_ACCESS_POJET_INTERVENTION');
        }
    },
    accessHealth: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_ACCESS_SANTE');
        }
    },
    getBirthname() {
      return this.client.birthName;
    },
    getBirthFirstname() {
      return this.client.birthFirstname;
    },
    getBirthDate() {
      const d = new Date(this.client.birthDate);
      const year_age = new Date();
      const day = (`0${d.getDate()}`).slice(-2);
      const month = (`0${d.getMonth() + 1}`).slice(-2);
      const year = d.getFullYear();
      const age = year_age.getFullYear() - year;
      return `${day}/${month}/${year}, ${age} ANS`;
    },
    getSex() {
      var sex = "INDETERMINE";

      if (this.client.sex == "F") {
        sex = "FEMININ"
      }

      if (this.client.sex == "M") {
        sex = "MASCULIN"
      }

      return sex;
    },
    getCodeInsee() {
      return this.client.codeInsee;
    },
    getIdentificationStatus() {
      const identificationStatus = this.client.identificationStatus?.label || 'Identité provisoire';
      return identificationStatus?.charAt(0).toUpperCase() + identificationStatus?.slice(1);
    },
    profilPicture() {
      const docs = this.client.documents ? this.client.documents.filter(item => item.mainType === "profil") : []

      if(docs.length > 0) {
        return docs[0];
      }

      return null;
    },
    additionalInformation(){
      return this.evaluation.additionalInfos && this.evaluation.additionalInfos !== '' ? JSON.parse(this.evaluation.additionalInfos) : [];
    },
    displayNotification() {
      if(this.evaluation && this.evaluation.materials) {
        for(let link of this.evaluation.materials) {
          if(link.suggested && !link.existing && !link.required) {
            return true;
          }
        }
      }
      return false;
    },
    style() {
      var s
      if(this.routeName === 'identity'){
        s = 25
      } else if(this.routeName === 'autonomy'){
        s = 170
      }else if(this.routeName === 'soin'){
        s = 310
      }else if(this.routeName === 'project-life'){
        s = 480
      }
      return `transform:translateX(${s}px)`;
    }
  },

  methods: {
    ...mapActions("Client", ["postClientContact", "putClientContact", "deleteClientContact"]),
    ...mapActions("Evaluation", ["postAssignRisk", "putValidateRisks","getEvaluationMaterials", "postGeneralAdditionalInformation"]),
    ...mapMutations('Evaluation',['setRisk', 'setEvaluationProperty']),
    showHistoryModal() {
      this.modalHistory = !this.modalHistory;
    },
    showRisksModal() {
      this.modalRisks = true
    },
    removeClientContact(contactId) {
      this.deleteClientContact({contactId: contactId}).then((res) => {
        this.$notify.success('Le contact a été supprimé');
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    assignRisk(name) {
      this.setRisk({
        id: this.evaluation.id,
        name: name
      });
      this.postAssignRisk().then((res) => {
        this.$notify.success('Le risque a été enregistré');
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    validateRisks(checkedRisks) {
      this.putValidateRisks({checklist: checkedRisks}).then((res) => {
        //this.$notify.success('Le risque a été enregistré');
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    updateClientContact(contact) {
      this.putClientContact({contact: contact}).then((res) => {
        this.$notify.success('Le contact a été modifié');
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    validateInfos(info) {
      this.setEvaluationProperty({
        name: "additionalInfos",
        value:  JSON.stringify(info)
      });
      this.postGeneralAdditionalInformation().then((res) => {
        this.$notify.success("L'information a été modifiée.");
        return res;
      }).catch((err) => {
        this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        throw err;
      });
    },
    async openModalAutonomie() {
      await this.getEvaluationMaterials({id: this.$route.params.evaluationId});
      this.modalAutonomie = true;
    },
    setMainStyle() {
      if(!this.$refs.topNav) {return;}
      this.mainStyle = {
        marginTop: this.$refs.topNav.clientHeight + "px"
      }
    }
  },
  mounted() {
    this.setMainStyle();
    window.addEventListener('resize', this.setMainStyle);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setMainStyle);
  }
};
</script>
