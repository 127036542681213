<template>
  <auth-wrap class="accueil">
    <FormEvaluation
      :showCreateEvaluation="showCreateEvaluationModal"
      :beginEvaluation="showEvaluationDuplicate"
      :disabled="!selectedClient.id"
      :openMaps="openMaps"
      :selectedClient="selectedClient"
      @onSelect="onSelectUser"
    />
    <custom-modal :active="evaluationModal" @close="evaluationModal = false">
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">
          Évaluations de
          {{ selectedClient.sex === "M" ? "Monsieur" : "Madame" }}
          {{ selectedClient.firstname }} {{ selectedClient.lastname }}
          <font-awesome-icon
            v-if="evaluationModalLoader"
            :icon="['fas', 'spinner']"
            spin
          />
        </h1>
        <div
          class="row cursor-pointer p-3"
          v-for="(evaluation, index) in selectedClient.evaluation"
          :key="`modal-eval-${index}`"
        >
          <div class="col-3" @click.prevent="gotToEval(evaluation.id)">
            {{ evaluation.evaluateBy.firstName }}
            {{ evaluation.evaluateBy.lastName }}
          </div>
          <div class="col-3" @click.prevent="gotToEval(evaluation.id)">
            {{
              evaluation.startingDate || evaluation.createdAt
                ? formatDate(
                    evaluation.startingDate
                      ? evaluation.startingDate
                      : evaluation.createdAt
                  )
                : ""
            }}
            -
            {{ evaluation.endingDate ? formatDate(evaluation.endingDate) : "" }}
          </div>
          <div class="col-3" @click.prevent="gotToEval(evaluation.id)">
            <div
              class="eval--progress"
              :style="{
                width: `${parseInt(evaluation.aggirPercentage * 1.3)}px`,
              }"
            >
              <span class="eval--progress-bar-text"
                >AGGIR | {{ evaluation.aggirPercentage }}%</span
              >
            </div>
          </div>
          <div class="col-3">
            <font-awesome-icon
              class="ic ic-danger ml-4 mr-1"
              :icon="['fas', 'trash']"
              @click.prevent="removeEvaluation(evaluation.id, index)"
            />
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button
            class="btn btn-primary"
            @click.prevent="evaluationModal = false"
          >
            Retour
          </button>
        </div>
      </div>
    </custom-modal>
    <custom-modal
      :active="evaluationDuplicateModal"
      @close="evaluationDuplicateModal = false"
    >
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">
          Sélectionner une évaluation précédente ou démarrer une nouvelle
          évaluation pour
          {{ selectedClient.sex === "M" ? "Monsieur" : "Madame" }}
          {{ selectedClient.firstname }} {{ selectedClient.lastname }}
          <font-awesome-icon
            v-if="evaluationDuplicateLoader"
            :icon="['fas', 'spinner']"
            spin
          />
          <font-awesome-icon v-show="loading" :icon="['fas', 'spinner']" spin />
        </h1>
        <div
          class="row cursor-pointer p-3"
          v-for="(evaluation, index) in selectedClient.evaluation"
          :key="`modal-eval-${index}`"
        >
          <div class="col-3" @click.prevent="gotToEval(evaluation.id)">
            {{ evaluation.evaluateBy.firstName }}
            {{ evaluation.evaluateBy.lastName }}
          </div>
          <div class="col-3" @click.prevent="gotToEval(evaluation.id)">
            {{
              evaluation.startingDate || evaluation.createdAt
                ? formatDate(
                    evaluation.startingDate
                      ? evaluation.startingDate
                      : evaluation.createdAt
                  )
                : ""
            }}
            -
            {{ evaluation.endingDate ? formatDate(evaluation.endingDate) : "" }}
          </div>
          <div class="col-3" @click.prevent="gotToEval(evaluation.id)">
            <div
              class="eval--progress"
              :style="{
                width: `${parseInt(evaluation.aggirPercentage * 1.3)}px`,
              }"
            >
              <span class="eval--progress-bar-text"
                >AGGIR | {{ evaluation.aggirPercentage }}%</span
              >
            </div>
          </div>
          <div class="col-3">
            <button
              class="btn btn-primary"
              @click.prevent="createEvaluationWithExistClient(evaluation.id)"
            >
              Dupliquer
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button
            class="btn btn-primary"
            @click.prevent="createEvaluationWithExistClient(null)"
          >
            Démarrer une nouvelle évaluation
            <font-awesome-icon
              v-show="loading"
              :icon="['fas', 'spinner']"
              spin
            />
          </button>
          <button
            class="btn btn-outline-primary ml-2"
            @click.prevent="evaluationDuplicateModal = false"
          >
            Retour
          </button>
        </div>
      </div>
    </custom-modal>
    <custom-modal
      :active="modalCreateEvaluationWithoutClient"
      @close="modalCreateEvaluationWithoutClient = false"
    >
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">
          Ajouter un nouveau client et démarrer l'évaluation
        </h1>
        <div class="row">
          <div class="col-6">
            <form-input
              v-model="ins"
              label="Matricule INS :"
              :login-input="false"
              :disabled="true"
              :max="15"
            />
          </div>
          <div class="col-6">
            <form-autocomplete
              v-model="structureId"
              :list="structureList"
              label="La structure * :"
              :error="
                $v.structureId.$error ? 'La structure est obligatoire.' : null
              "
              :login-input="false"
              @onInput="onInputStructure"
            />
          </div>
          <div class="col-6">
            <form-input
              v-model="oid"
              label="OID :"
              :login-input="true"
              :disabled="true"
              :max="20"
            />
          </div>
          <div class="col-6">
            <form-select
              v-model="agencyId"
              :options="agencyList"
              label="Agence * :"
              :error="$v.agencyId.$error ? `L'agence est obligatoire.` : null"
              :disabled="false"
            />
          </div>
          <div class="col-6">
            <form-input
              v-model="birthName"
              label="Nom de naissance * :"
              :login-input="false"
              name="checkControl"
              :error="$v.birthName.$error ? 'Le nom de naissance est obligatoire.' : null"
              :disabled="false"
            />
          </div>
          <div class="col-6">
            <form-select
              v-model="sex"
              label="Sexe * :"
              :disabled="false"
              :error="$v.sex.$error ? 'Le sexe est obligatoire.' : null"
              :login-input="false"
            >
              <template v-slot:first>
                <option value="M">Masculin</option>
                <option value="F">Féminin</option>
                <option value="I">Indéterminé</option>
              </template>
            </form-select>
          </div>
          <div class="col-6">
            <form-input
              v-model="birthFirstnames"
              label="Prénom(s) de naissance * :"
              name="checkControl"
              :error="$v.birthFirstnames.$error ? 'Le champ Prénom(s) de naissance est obligatoire.' : null"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-6">
            <form-input
              v-model="lastName"
              label="Nom utilisé :"
              name="checkControl"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-6">
            <FormDatePicker
              v-model="birthDate"
              label="Date de naissance * :"
              :error="$v.birthDate.$error ? 'Le date de naissance est obligatoire.' : null"
              :login-input="false"
            >
            </FormDatePicker>
          </div>
          <div class="col-6">
            <form-input
              v-model="birthFirstname"
              label="1er Prénom de naissance * :"
              name="checkControl"
              :error="errorMessages"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-6">
            <SelectCountry
              :label="'Pays de naissance :'"
              :value="birthCountry"
              @setValue="setBirthCountry"
            />
          </div>
          <div class="col-6">
            <form-input
              v-model="firstName"
              label="Prénom utilisé :"
              name="checkControl"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-6">
            <City 
              @codeInsee="setCity" 
              forCity="true" 
              :disabled="birthCountry === 'France' ? false : true"
              :selectedCity="birthCity"
            />
          </div>
          <div class="col-6">
            <form-input
              v-model="socialSecurityNumber"
              label="Numéro de sécurité sociale * :"
              :error="
                $v.socialSecurityNumber.$error
                  ? 'Le Numéro de sécurité sociale est obligatoire et doit être au format : x xx xx xx xxx xxx xx.'
                  : null
              "
              :login-input="false"
              :disabled="false"
              @input="changeSocialSecurityNumberFormat"
            />
          </div>
          <div class="col-6">
            <form-input
              v-model="codeInsee"
              label="Code lieu de naissance (Code INSEE) * :"
              :error="$v.codeInsee.$error ? 'Le code INSEE est obligatoire.' : null"
              :login-input="false"
              :disabled="true"
              :max="5"
            />
          </div>
          <div class="col-6">
            <SelectCountry
              :label="'Nationalité * :'" 
              :value="nationality" 
              :error="$v.nationality.$error" 
              @setValue="setCountry"
            />
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button class="btn btn-primary" @click.prevent="createEvaluation">
            Valider
          </button>
        </div>
      </div>
    </custom-modal>
    <custom-modal
      :active="modalCreateEvaluationWithClient"
      @close="modalCreateEvaluationWithClient = false"
    >
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">
          Choisissez une agence parmi les agences du client
        </h1>
        <div class="row">
          <div class="col-12">
            <form-select
              v-model="agencyId"
              :options="selectedClientAgencies"
              label="Agence :"
              :error="
                !$v.agencyId.required && $v.agencyId.$error
                  ? `L'agence est obligatoire.`
                  : null
              "
              :disabled="false"
              :login-input="false"
            >
              <template v-slot:first>
                <option value="" selected></option>
              </template>
            </form-select>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button
            class="btn btn-primary"
            @click.prevent="createEvaluationWithChooseAgency"
          >
            Valider
          </button>
        </div>
      </div>
    </custom-modal>
    <custom-modal
      :active="navigationModal"
      @close="navigationModal = false"
      :dialogWidth="'300px'"
    >
      <div class="add-act-modal">
        <h1 class="cuisine-modal--title">Itinéraire</h1>
        <div class="row accueil--modal-navigation">
          <a :href="gmapUrl" target="_blank">
            <img src="@/assets/google-maps.png" alt />
          </a>
          <a :href="wazeUrl" target="_blank">
            <img src="@/assets/waze.png" alt />
          </a>
        </div>
      </div>
    </custom-modal>
  </auth-wrap>
</template>

<script>
import Moment from "moment";
import FormAutocomplete from "@/components/Form/Autocomplete";
import City from "@/components/Form/City";
import SelectCountry from "@/components/Form/SelectCountry";
import FormDatePicker from "@/components/Form/DatePicker";
import {
  formatSecuritySocialNumber,
  formatDate,
} from "@/components/Util/UtilFunction";
import { mapState, mapActions, mapMutations } from "vuex";
import { LOCAL_STORAGE_EVALUATION_ID } from "@/components/Util/EnvVariable";
import { required } from "vuelidate/lib/validators";
import FormEvaluation from "./FormEvaluation.vue";

export default {
  name: "Accueil",
  data() {
    return {
      firstName: "",
      lastName: "",
      structureId: "",
      agencyId: "",
      birthDate: "",
      birthCity: "",
      birthCountry: "",
      birthFirstname: "",
      birthFirstnames: "",
      sex: "",
      ins: "",
      oid: "",
      birthName: "",
      codeInsee: "",
      socialSecurityNumber: "",
      nationality: "",
      disabled: false,
      selectedClient: {},
      gmapUrl: "",
      wazeUrl: "",
      navigationModal: false,
      evaluationModal: false,
      evaluationModalLoader: false,
      evaluationDuplicateModal: false,
      evaluationDuplicateLoader: false,
      modalCreateEvaluationWithClient: false,
      modalCreateEvaluationWithoutClient: false,
      onInputUserCancel: null,
      loading: false,
    };
  },
  validations() {
    let dataReturn = {
      birthName: {
        required,
      },
      sex: {
        required,
      },
      birthFirstnames: {
        required
      },
      birthFirstname: {
        isSameFirstName() {
          return this.birthFirstnames.split(' ')[0] == this.birthFirstname;
        },
        required,
      },
      birthDate: {
        required,
      },
      nationality: {
        required,
      },
      codeInsee: {
        required: this.birthCountry === 'France' ? true : false,
      },
      structureId: {
        required,
      },
      agencyId: {
        required,
      },
      socialSecurityNumber: {
        isSocialNumber(value) {
          return value.length === 21;
        },
        required,
      },
    };
    return dataReturn;
  },
  components: {
    FormAutocomplete,
    FormDatePicker,
    City,
    SelectCountry,
    FormEvaluation
  },
  mounted() {
    let evaluationId = window.sessionStorage.getItem(
      LOCAL_STORAGE_EVALUATION_ID
    );
    if (evaluationId) {
      return this.gotToEval(evaluationId);
    }
    this.getStructureList({}).then(() => {
      return this.getUserConnected().then((res) => {
        this.structureId = this.connectedUser.structure
          ? this.connectedUser.structure.id
          : "";
        return res;
      });
    });
  },
  methods: {
    ...mapActions("Client", ["getClientList", "getClient", "postClient"]),
    ...mapActions("Structure", ["getStructureList"]),
    ...mapActions("Evaluation", [
      "postEvaluation",
      "duplicateEvaluation",
      "deleteEvaluation",
    ]),
    ...mapActions("User", ["getUserConnected"]),
    ...mapMutations("Client", [
      "setInitClient",
      "setClientProperty",
      "setPutClientSuccess",
    ]),
    ...mapMutations("Evaluation", [
      "setInitEvaluation",
      "setEvaluationProperty",
    ]),
    formatDate,
    onSelectUser(clientId) {
      this.selectedClient = this.clients.find(
        (client) => client.id === clientId
      );
      this.evaluationModalLoader = true;
      this.getClient({ id: this.selectedClient.id })
        .then(({ client }) => {
          this.selectedClient = client;
          this.evaluationModalLoader = false;
        })
        .catch(() => {
          this.evaluationModalLoader = false;
        });
    },
    setBirthCountry(value) {
      if(value === "France") {
        this.birthCountry = value;
      } else {
        this.birthCountry = value;
        this.birthCity = '';
        this.codeInsee = '';
      }
    },
    setCountry(value) {
      this.nationality = value;
    },
    setCity(value) {
      this.codeInsee = value.code;
      this.birthCity = value.nom;
    },
    onInputUser(query) {
      this.loading = true;
      query.toUpperCase;
      return this.getClientList({
        filters: ['{"column" : "fullName", "val" : "' + query + '"}'],
      }).then(() => {
        this.loading = false;
      });
    },
    onInputStructure(query) {
      this.structureLoading = true;
      return this.getStructureList({
        filters: ['{"column" : "name", "val" : "' + query + '"}'],
      }).then(() => {
        this.structureLoading = false;
      });
    },
    createEvaluationWithExistClient(evaluationId = null) {
      if (this.selectedClientAgencies.length === 1) {
        this.agencyId = this.selectedClientAgencies[0].value;
        this.startEvaluation(evaluationId);
      } else {
        this.agencyId = "";
        this.modalCreateEvaluationWithClient = true;
      }
    },
    startEvaluation(evaluationId = null) {
      this.setInitEvaluation();
      this.setEvaluationProperty({
        name: "structureId",
        value: this.structureId,
      });
      this.setEvaluationProperty({
        name: "agencyId",
        value: this.agencyId,
      });
      this.loading = true;

      if (evaluationId) {
        this.duplicateEvaluation({ id: evaluationId })
          .then((res) => {
            this.gotToEval(res.duplicateEvaluation.id);
            this.loading = false;
            return res;
          })
          .catch((err) => {
            this.$notify.error(
              "Vous ne pouvez pas sélectionner cette évaluation. " +
                "Vous pouvez essayer de sélectionner une autre évaluation ou démarrer une évaluation vide."
            );
            this.loading = false;
            throw err;
          });
      } else {
        this.postEvaluation(this.selectedClient)
          .then((res) => {
            this.gotToEval(res.saveEvaluation.id);
            this.loading = false;
            return res;
          })
          .catch((err) => {
            this.$notify.error("Erreur de création de l'évaluation !");
            this.loading = false;
            throw err;
          });
      }
    },
    gotToEval(evaluationId) {
       this.$router.push({ name: "identity", params: { evaluationId } });

      const protocol = window.location.protocol;
      const domain = window.location.hostname;
      const port = window.location.port;
      const fullDomain = port ? `${protocol}//${domain}:3001` : `${protocol}//${domain}`;

      window.location.href = `${fullDomain}/new/evaluation/${evaluationId}/identite/?idClient=${this.selectedClient.id}`;
    },
    changeSocialSecurityNumberFormat() {
      this.socialSecurityNumber = formatSecuritySocialNumber(
        this.socialSecurityNumber
      );
    },
    removeAccentsAndDiacritics(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
    },
    showCreateEvaluationModal() {
      this.firstName = "";
      this.lastName = "";
      this.firstNameBirth = "";
      this.birthName = "";
      this.birthFirstnames = "";
      this.birthFirstname = "";
      this.sex = "";
      this.birthCity = "";
      this.birthCountry = "";
      this.socialSecurityNumber = "";
      this.structureId = "";
      this.agencyId = "";
      this.birthDate = "";
      this.codeInsee = "";
      this.nationality = "";
      this.firstNameOfBirth = "";
      this.modalCreateEvaluationWithoutClient = true;
    },
    showEvaluationDuplicate() {
      this.evaluationDuplicateModal = true;
    },
    createEvaluationWithChooseAgency() {
      if (this.$v.$invalid) {
        if (!this.$v.agencyId.required) {
          this.$v.$touch();
          return;
        }
      }
      this.startEvaluation();
      this.modalCreateEvaluationWithClient = false;
    },
    createEvaluation() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let socialSecurityNumber = this.socialSecurityNumber.split(" ").join("");
      this.loading = true;
      let momentDate = Moment(this.birthDate);
      momentDate.add(10, 'hours');
      const date = momentDate.format('DD-MM-YYYY');
      console.log(this.birthDate, date);
      this.getClientList({
        filters: [
          '{"column" : "social_security_number", "val" : "' +
            socialSecurityNumber +
            '"}',
        ],
      })
        .then((res) => {
          const existClient = res.clientList.total
            ? res.clientList.items[0]
            : null;
          if (existClient) {
            this.onSelectUser(existClient.id);
            this.$notify.success("Le client existe déjà");
          } else {
            this.setInitClient();
            this.setClientProperty({
              name: "firstname",
              value:
                this.firstName.charAt(0).toUpperCase() +
                this.firstName.slice(1)
            });
            this.setClientProperty({
              name: "lastname",
              value: this.lastName.toUpperCase()
            });
            this.setClientProperty({
              name: "socialSecurityNumber",
              value: this.socialSecurityNumber
            });
            this.setClientProperty({
              name: "structureId",
              value: this.structureId
            });
            this.setClientProperty({
              name: "birthName",
              value: this.removeAccentsAndDiacritics(this.birthName)
            });
            this.setClientProperty({
              name: "sex",
              value: this.sex
            });
            this.setClientProperty({
              name: "birthCity",
              value: this.birthCity
            });
            this.setClientProperty({
              name: "birthCountry",
              value: this.birthCountry
            });
            this.setClientProperty({
              name: "birthFirstnames",
              value: this.removeAccentsAndDiacritics(this.birthFirstnames)
            }),
            this.setClientProperty({
              name: "birthFirstname",
              value: this.removeAccentsAndDiacritics(this.birthFirstname)
            }),
            this.setClientProperty({
              name: "codeInsee",
              value: this.codeInsee
            });
            this.setClientProperty({
              name: "nationality",
              value: this.nationality
            });
            this.setClientProperty({
              name: "agencyId",
              value: this.agencyId
            });
            this.setClientProperty({
              name: "birthDate",
              value: date
            });
            this.setEvaluationProperty({
              name: "structureId",
              value: this.structureId
            });
            this.postClient().then((c) => {
              this.selectedClient = c.saveClient;
              this.$notify.success("Le client a été ajouté");
              this.startEvaluation();
              this.modalCreateEvaluationWithoutClient = false;
            }).catch(() => {
              this.$notify({
                title: 'Warning',
                message: "Un client avec le même nom et prénom existe déjà.",
                type: 'warning',
              });
              this.modalCreateEvaluationWithoutClient = true;
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openMaps() {
      if (this.selectedClient.id) {
        const address = this.selectedClient.address
          ? this.selectedClient.address
          : "";
        const city = this.selectedClient.city ? this.selectedClient.city : "";
        const postalCode = this.selectedClient.postalCode
          ? this.selectedClient.postalCode
          : "";
        const destination = encodeURI(`${address},${city} ${postalCode}`);

        this.gmapUrl = `https://www.google.fr/maps/place/${destination}`;
        this.wazeUrl = `https://waze.com/ul?q=${destination}`;

        this.navigationModal = true;
      }
    },
    onFocusSelect() {
      const elementByKey = document.getElementsByClassName("wrapper--auth");
      if (Array.isArray(elementByKey)) {
        elementByKey.forEach((element) => {
          element.scroll({
            top: 500,
            left: 0,
            behavior: "smooth",
          });
        });
      }
    },
    removeEvaluation(id, index) {
      //refresh client values and evaluation number
      this.selectedClient.evaluation.splice(index, 1);
      this.selectedClient.evaluationNumber =
        this.selectedClient.evaluation.length;
      let clientIndex = this.clients.findIndex(
        (client) => this.selectedClient.id === client.id
      );
      if (clientIndex !== -1) {
        let client = this.clients[clientIndex];
        client.evaluationNumber = this.selectedClient.evaluation.length;
        this.setPutClientSuccess(client);
      }

      this.evaluationModalLoader = true;
      this.deleteEvaluation({ id })
        .then(() => {
          this.evaluationModalLoader = false;
        })
        .catch(() => {
          //refresh client only if there are an error
          this.onSelectUser(this.selectedClient.id);
          this.evaluationModalLoader = false;
          this.$notify.error(
            "L'évaluation ne peut pas être supprimée. Contacter le service d'administration."
          );
        });
    },
  },
  computed: {
    ...mapState("Client", ["clients"]),
    ...mapState("Structure", ["structures"]),
    ...mapState("User", ["connectedUser"]),
    errorMessages() {
      if (this.$v.birthFirstname.$error) {
        if (!this.$v.birthFirstname.required) {
          return 'Le 1er prénom de naissance est obligatoire.';
        } else if (!this.$v.birthFirstname.isSameFirstName) {
          return 'Le 1er prénom de naissance ne correspond pas au 1er prénom de Prénom(s) de naissance.';
        }
      }
      return null
    },
    clientList() {
      return this.clients.reduce((list, client) => {
        list.push({
          value: client.id,
          label: `${client.firstname} ${client.lastname}`,
        });
        return list;
      }, []);
    },
    agencyList() {
      const structure = this.structures.find(
        (item) => item.id === this.structureId
      );
      if (structure) {
        if (structure.agencies) {
          return structure.agencies.reduce((list, agency) => {
            list.push({ value: agency.id, text: agency.companyName });
            return list;
          }, []);
        }
      }
      return [];
    },
    selectedClientAgencies() {
      if (this.selectedClient) {
        if (this.selectedClient && Array.isArray(this.selectedClient.agency)) {
          if (this.selectedClient.agency.length > 0) {
            return this.selectedClient.agency.reduce((list, agency) => {
                  list.push({
                    value: agency.id,
                    text:
                        (agency.structure ? agency.structure.name + " - " : "") +
                        agency.companyName,
                  });
                  return list;
                }, [])
          } else {
            // if client does not have agency, we find agencies connected to client structure
            const clientStructure = this.structures.find(
                (item) => item.id === this.selectedClient.structure[0].id
            );
            if (clientStructure) {
              if (clientStructure.agencies) {
                return clientStructure.agencies.reduce((list, agency) => {
                  list.push({ value: agency.id, text: agency.companyName });
                  return list;
                }, []);
              }
            }
          }
        }
      }
      return [];
    },
    structureList() {
      return this.structures.reduce((list, structure) => {
        list.push({ value: structure.id, label: structure.name });
        return list;
      }, []);
    },
    oldSelectedUser() {
      if (!this.selectedClient || !this.selectedClient.birthDate) {
        return "Non renseigné";
      }
      let old =
        Moment().format("YYYY") -
        Moment(this.selectedClient.birthDate).format("YYYY");
      return `${old} ans`;
    },
  },
};
</script>
<style scoped>
.eval--progress {
  background-color: #40ceba;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 15px;
}
.eval--progress-bar-text {
  padding: 0 14px 0 14px;
}
</style>
