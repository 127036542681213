<template>
    <div class="wrapper wrapper--auth">
      <img class="ai ai--logo" src="@/assets/path-3.svg" alt="">
      <img class="ai ai--bottom-shade" src="@/assets/path-4.svg" alt="">
      <img class="ai ai--illu_2" src="@/assets/illu_2@2x.png" alt="">
      <img class="ai ai--illu_1" src="@/assets/illu_1@2x.png" alt="">
      <header class="authWrap__header">
        <div class="container-fluid">
          <NavBar/>
        </div>
      </header>
      <div class="ml-auto mr-5">
            <button class="btn btn-lg btn-outline-white" v-text="buttonLabel" @click="redirectToApp"></button>
            </div>
      <main>
        <div class="auth--content">
            <div class="auth--card">
                <slot />
            </div>
        </div>
      </main>
      <Footer></Footer>
    </div>
</template>
<script>
import NavBar from '@/layouts/NavBar';
import Footer from '@/components/Footer';

export default {
  name: "AuthWrap",
  components: {
    NavBar,
    Footer
  },
  computed: {
    buttonLabel () {
      if (this.$route.path.includes('evaluation')) {
        return 'Accéder au back office'
      } else {
        return 'Accéder à l\'évaluation'
      }
    },
    accessBackoffice: {
      get() {
        let storedPermissions = sessionStorage.getItem('permissions');
        let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

        return permissionsArray.includes('CAN_BACK_OFFICE');
      }
    }
  },
  methods: {
    redirectToApp () {
      if (this.$route.path.includes('evaluation')) {
        this.$router.push('/login')
      } else {
        this.$router.push('/evaluation-login')
      }
    }
  }
};
</script>
<style>
  .authWrap__header {
    background: white;
    margin-bottom: 50px;
  }
</style>