<template>
    <eval-wrap v-if="this.$route.path.includes('evaluation')">
        <Mention />
    </eval-wrap>
    <cgu-wrap  v-else-if="this.$route.path === '/mentions'">
        <Mention />
    </cgu-wrap>
    <Mention v-else />
</template>
<script>
import Mention from '@/components/CGU/Mention';
export default {
    name: "MentionView",
    components: {
        Mention
    }
}
</script>