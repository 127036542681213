<template>
    <eval-wrap v-if="this.$route.path.includes('evaluation')">
        <Rgpd />
    </eval-wrap>
    <cgu-wrap  v-else-if="this.$route.path === '/rgpd'">
        <Rgpd />
    </cgu-wrap>
    <Rgpd v-else />
</template>
<script>
import Rgpd from '@/components/CGU/Rgpd';
export default {
    name: "RgpdView",
    components: {
        Rgpd
    }
}
</script>