<template>
    <div class="d-flex p-4 container-fluid" style="background: #E8E7E7;">
        <div class="id--info-photo cursor-pointer">
          <div class="id--info-photo--avatar" v-if="profilPicture" :style="{ backgroundImage: `url(${profilPicture ? API_URL + profilPicture.path : ''})` }">
          </div>
          <img v-else class="id--info-photo--avatar" src="@/assets/photo-id.svg"/>
        </div>
        <div class="col-12">
            <div class="id--info-item">
                <div class="id--info-wrap">
                    <div class="d-flex align-items-center">
                        <p class="mr-3"><span class="font-weight-bold">N.Nais : </span>{{ getBirthname }}</p>
                        <p class="mr-3"><span class="font-weight-bold">Pr.1 : </span>{{ getBirthFirstname }}</p>
                        <p class="mr-3"><span class="font-weight-bold">DDN : </span>{{ getBirthDate }}</p>
                        <p class="mr-3"><span class="font-weight-bold">S : </span>{{ getSex }}</p>
                        <p class="mr-3"><span class="font-weight-bold">INSEE Nais. : </span>{{ getCodeInsee }}</p>
                    </div>
                    <div class="col-12 id--section-container-state-banner">
                    <div :class="statusClasses">{{ getIdentificationStatus }}</div>
                    <button class="id--info-button" @click="openModal"><img src="@/assets/info.svg"/></button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
  
<script>
  export default {
    props: {
      profilPicture: String,
      getBirthname: String,
      getBirthFirstname: String,
      getBirthDate: String,
      getSex: String,
      getCodeInsee: String,
      getIdentificationStatus: String,
      openModal: Function
    },
    computed: {
    statusClasses() {
      return {
        'id--section-state-provisoire': this.getIdentificationStatus === 'Identité provisoire',
        'id--section-state-recuperee': this.getIdentificationStatus === 'Identité récupérée',
        'id--section-state-validee': this.getIdentificationStatus === 'Identité validée',
        'id--section-state-qualifiee': this.getIdentificationStatus === 'Identité qualifiée',
      };
    }
  }
  };
</script>
<style scoped>
  .id--info-button {
    border: transparent;
    background: transparent;
    padding-left: 1rem;
  }
</style>