import Vue from 'vue';
import Vuex from 'vuex';

import User from './Modules/User';
import Agency from './Modules/Agency';
import Prestation from './Modules/Prestation';
import Structure from './Modules/Structure';
import Notification from './Modules/Notification';
import Tarification from './Modules/Tarification';
import Contract from './Modules/Contract';
import Client from './Modules/Client';
import IdentityDocument from './Modules/IdentityDocument';
import Insi from './Modules/Insi';
import Filter from './Modules/Filter';
import Evaluation from './Modules/Evaluation';
import AggirQuestion from './Modules/AggirQuestion';
import Material from './Modules/Material';
import Contact from './Modules/Contact';
import Intervention from './Modules/Intervention';
import Alert from './Modules/Alert';
import CarePlanPrestation from './Modules/CarePlanPrestation';
import TypeAide from './Modules/TypeAide';
import History from './Modules/History';
import Roles from './Modules/Roles';

Vue.use(Vuex);

let storeConfig = {
    modules: {
        User,
        Prestation,
        Agency,
        Structure,
        Notification,
        Tarification,
        Contract,
        Filter,
        Client,
        IdentityDocument,
        Insi,
        Evaluation,
        AggirQuestion,
        Material,
        Contact,
        Intervention,
        Alert,
        CarePlanPrestation,
        TypeAide,
        History,
        Roles
    }
};


const store = new Vuex.Store(storeConfig);

export default store;