import Vue from "vue";
import VueRouter from "vue-router";

import Login from '@/views/Auth/Login';
import LoginPsc from '@/views/Auth/LoginPSC';
import forgotPassword from '@/views/Auth/ForgotPassword';
import resetPassword from '@/views/Auth/ResetPassword';
import Cgu from '@/views/CGU/Cgd';
import Rgpd from '@/views/CGU/Rgpd';
import Mention from '@/views/CGU/Mention';

import BO from './BO';
import Evaluation from './Evaluation';

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        redirect: {name: "login"}
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        alias: "/evaluation-login"
    },
    {
        path: "/login-psc",
        name: "loginPsc",
        component: LoginPsc
    },
    {
        path: "/forgotPassword",
        name: "forgotPassword",
        component: forgotPassword,
    },
    {
        path: "/resetPassword/:id",
        name: "resetPassword",
        component: resetPassword,
    },
    {
        path: "/cgu",
        name: "cgu",
        component: Cgu
    },
    {
        path: "/rgpd",
        name: "rgpd",
        component: Rgpd
    },
    {
        path: "/mentions",
        name: "mentions",
        component: Mention
    },
    ...BO,
    ...Evaluation
];

const router = new VueRouter({
   // mode: "history",
    routes
});

export default router;
